import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Mental Health and Wellbeing Policy">
    <Hero title="Mental Health and Wellbeing Policy" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Text>
              We care about the mental health and wellbeing of the entire Ember
              team. This policy sets out why mental health is important and what
              to do if you are experiencing mental health difficulties. It's
              doesn't try to be a comprehensive guide to managing mental health
              since everyone's situation is different. However, you'll find a
              link to an NHS guide on mental health at the end which offers
              further guidance.
            </Text>
            <Heading type="title2">Why is mental health important?</Heading>
            <Text>
              Having happy, engaged employees is important to us as a business.
              It improves our customer service, reduces the risk of people
              calling in sick at the last minute and avoids expensive errors.
              It's also important to us as people.
            </Text>
            <Text>
              Mental health issues can be caused by something that is happening
              at work (e.g. stress), something that is happening at home or by
              an underlying medical condition. We care about all of these
              things. Just because something is happening at home, you shouldn't
              feel that you can't talk to us about it – it will inevitably
              impact your work performance and there may be changes we can make
              to make things a bit better.
            </Text>
            <Text>
              Mental health is not a fringe issue that only affects a few
              people. Almost everyone gets stressed at times and many people
              suffer other mental health issues over the course of their lives.
              This means you shouldn't feel embarrassed or ashamed to talk about
              it (but we will always respect confidentiality if you wish).
            </Text>
            <Heading type="title3">
              Looking after your mental health and wellbeing
            </Heading>
            <Text>
              One of the most important steps you can take to improve your
              mental health is to take the time to think about it. There may be
              some simple changes you can make that will improve your mental
              health. These include:
            </Text>
            <List>
              <ListItem>
                Eating healthily: Make sure you are eating a balanced diet,
                including enough fruit. If you are doing shift work, it's
                important to make sure you are still having regular and full
                meals
              </ListItem>
              <ListItem>
                Drinking enough: Most adults should be having at least 6-8
                glasses of water per day. If you are not drinking enough,
                carrying a water bottle may help
              </ListItem>
              <ListItem>
                Getting enough exercise: Exercise and physical activity can help
                to reduce stress and promote both physical and mental health.
                There are all sorts of kinds of exercise, whether it's spending
                time in the gym or going for a walk. It can also be helpful to
                build exercise into your day. For example, you might want to try
                cycling to work (speak to us about the Cycle to Work scheme if
                you are looking for a new bike)
              </ListItem>
              <ListItem>
                Reducing stress: Think through what makes you stressed, either
                at work or at home and whether there is something that could
                improve it. This might mean something like getting up a little
                earlier so you are not rushed in the mornings or taking ten
                seconds to stop and breathe before you move on to your next job.
                If you are a driver, you might find running late is stressful.
                The key thing is to communicate the delay clearly to passengers
                and then try to stop worrying about it. The traffic situation is
                out of your hands and most reasonable passengers understand that
              </ListItem>
            </List>
            <Text>
              It's also important to look out for the health and wellbeing of
              colleagues. That might be as simple as asking how someone is or
              being a bit more understanding if you know someone is having a
              tough time.
            </Text>
            <Heading type="title3">
              What to do if you are having mental health difficulties?
            </Heading>
            <Text>
              If you are struggling with mental health, the most important thing
              you can do is talk about it. This might be with a friend or
              colleague but it's also helpful to raise it with your
              supervisor/Keith/Pierce (whoever you feel most comfortable with)
              so that we can see if there are any changes we can make that will
              improve things.
            </Text>
            <Text>
              Ember has two qualified mental health first-aiders. Your line
              manager or anyone in the Operations team can refer you to them for
              one-to-one advice and support from a peer.
            </Text>
            <Text>
              Changes might include things like arranging some time off,
              reducing overtime or adjusting your hours. It might also be that
              we can talk through some methods to reduce stress or just apply a
              bit of extra discretion because we know you are having
              difficulties.
            </Text>
            <Text>
              When you talk to us, we will always respect your confidentially.
              In general, we might discuss the issue between your
              supervisor/Keith/Pierce but you can also tell us if you'd rather
              something remained completely private.
            </Text>
            <Text>
              It might also be a good idea to seek professional help outside of
              work. The NHS has a{" "}
              <TextLink
                type="secondary"
                external
                href="https://www.nhs.uk/mental-health/nhs-voluntary-charity-services/nhs-services/"
              >
                useful guide
              </TextLink>{" "}
              to some of the support that is available.
            </Text>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
